import React from "react"
import PropTypes from 'prop-types'
import { Link } from "gatsby"

const HomeHeroSection = ({ activeHome }) => (
  <div className="bg-gray-100 flex justify-center items-center px-4 pt-32 pb-12">
    <div className='w-full max-w-screen-xl flex flex-col md:flex-row items-center md:space-x-6 mx-auto'>
      <div className='w-full md:w-6/12 flex flex-col items-center md:items-start mx-auto'>
        <h1 className='text-4xl md:text-5xl font-medium text-gray-900 text-center mt-6'>
          <span className='leading-tight'>Squarespace Templates Guide</span>
        </h1>

        <h2 className='w-full md:text-lg text-gray-900 text-base text-center mt-6'>We share stories and solutions for the modern entrepreneur. Learn how to create a Squarespace website with amazing templates you'll find here.</h2>

        <div className="w-full flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8 mt-24">
          <Link to={activeHome ? "/" : "/blog"} activeClassName="active" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">All Categories</Link>
          <Link to="/" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">On Trend</Link>
          <Link to="/" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">Know</Link>
          <Link to="/" className="text-xl text-gray-400 hover:text-gray-800 hover:underline">Video</Link>
        </div>
      </div>
    </div>
  </div>
)

export default HomeHeroSection

HomeHeroSection.propTypes = {
  activeHome: PropTypes.bool
}