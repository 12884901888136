import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Date } from 'prismic-reactjs'

import Layout from "../components/layout/layout"
import HomeHeroSection from "../components/sections/home-hero-section"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      allPrismicBlogPost(
        limit: 100
        sort: {fields: data___date_published, order: DESC}
      ) {
        edges {
          node {
            id
            uid
            data {
              page_title
              date_published
              author
              category
              page_hero_background {
                alt
                gatsbyImageData(
                  width: 1920
                )
              }
            }
          }
        }
      }
    }
  `)

  const blogPosts = data.allPrismicBlogPost.edges

  return (
    <Layout>
      <Seo title="Squarespace Templates Guide Blog" />

      <HomeHeroSection activeHome />

      <div className="bg-gray-100 w-full px-4 pt-0 pb-32">

        <div className="w-full max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12">
            {blogPosts.map(item => {
              const blogImage = getImage(item.node.data.page_hero_background)
              const datePosted = Date(item.node.data.date_published)
              const formattedDate = new Intl.DateTimeFormat('en-US',{
                year: "numeric",
                month: "short",
                day: "numeric"
              }).format(datePosted);

              return (
                <div className="w-full">
                  <Link to={`/${item.node.uid}`}>
                    <GatsbyImage
                      image={blogImage}
                      alt={blogImage.alt}
                      className="border border-gray-300 hover:border-gray-900"
                    />
                  </Link>

                  <p className="text-gray-700 text-base font-medium uppercase mt-6">{item.node.data.category}</p>
                    <Link to={`/${item.node.uid}`}>
                      <h2 className="text-gray-800 hover:text-gray-900 font-medium text-3xl md:text-4xl hover:underline mt-2">{item.node.data.page_title}</h2>
                    </Link>
                  <p className="text-gray-700 text-sm mt-4">By {item.node.author} | {formattedDate}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
